import TrkdFilingsTable from "~/components/TrkdFilingsTable.vue";
export default defineComponent({
  components: {
    TrkdFilingsTable: TrkdFilingsTable
  },
  computed: {
    resData: function resData() {
      if (this.$store.state.trkd.tickerFilings) {
        return this.$store.state.trkd.tickerFilings;
      } else {
        return {
          filings: [],
          totalCount: 0
        };
      }
    },
    error: function error() {
      return this.$store.state.trkd.filingsError;
    },
    loading: function loading() {
      return this.$store.state.trkd.fetchingFilings;
    }
  },
  mounted: function mounted() {
    this.$emit("closechart"); // reFetch filings if it is over time threshold no fuck it
  }
});